
function Home() {
    return (
        <div>
            <h1>Home</h1>
            <div className='avatar'>
                
            </div>
        </div>
    );
}

export default Home