import IconBook from './Book';

function Icons() {
    return (
        <>
      
           <div className="flipBook">
            <h1>Icons Book</h1>
            <IconBook />
           </div>
        
        </>
    );
}

export default Icons
